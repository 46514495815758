import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, Segment, Grid, Icon } from "semantic-ui-react"

const SinglePostPage = () => {
  return (
    <Layout>
      <SEO title="Single Post | My Food Book " />

      <div style={{ margin: "100px 0" }}>
        <Card
          className="single-post-page-card"
          style={{ width: "90vw", maxWidth: "900px" }}
          image="https://source.unsplash.com/1600x900/?food,lunch"
          centered
          header={
            <h1 style={{ textAlign: "center" }}>
              This is the post title for the single post
            </h1>
          }
          meta={
            <Segment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "grey",
                }}
              >
                <div>
                  By : <b>John Doe</b>
                </div>
                <div>
                  Published On: <b>12 April, 2020</b>
                </div>
              </div>
            </Segment>
          }
          description={
            <div>
              <Segment>
                <h2>Ingrediants</h2>
                <ul>
                  <li>Item X</li>
                  <li>Item X</li>
                  <li>Item X</li>
                  <li>Item X</li>
                  <li>Item X</li>
                  <li>Item X</li>
                  <li>Item X</li>
                  <li>Item X</li>
                </ul>
              </Segment>
              <Segment style={{ fontSize: "18px" }}>
                <h2>Procedure</h2>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum. Duis aute irure dolor in reprehenderit in
                  voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum. Duis aute irure dolor in
                  reprehenderit in voluptate velit esse cillum dolore eu fugiat
                  nulla pariatur. Excepteur sint occaecat cupidatat non
                  proident, sunt in culpa qui officia deserunt mollit anim id
                  est laborum.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum.
                </p>
              </Segment>
              <Segment>
                <h2>Dish Gallery</h2>
                <Grid
                  columns={3}
                  centered
                  padded="vertically"
                  celled="internally"
                  stackable
                >
                  <Grid.Row>
                    <Grid.Column fluid>
                      <img
                        src="https://source.unsplash.com/1600x900/?food,lunch,1"
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src="https://source.unsplash.com/1600x900/?food,lunch,2"
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src="https://source.unsplash.com/1600x900/?food,lunch,3"
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src="https://source.unsplash.com/1600x900/?food,lunch,4"
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src="https://source.unsplash.com/1600x900/?food,lunch,5"
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src="https://source.unsplash.com/1600x900/?food,lunch,6"
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <h2>Share</h2>
                <div>
                  <Icon
                    circular
                    size="large"
                    color="blue"
                    name="facebook square"
                  />
                  <Icon circular size="large" color="violet" name="linkedin" />
                  <Icon circular size="large" color="teal" name="twitter" />
                  <Icon circular size="large" color="green" name="whatsapp" />
                </div>
              </Segment>
            </div>
          }
        />
      </div>
    </Layout>
  )
}

export default SinglePostPage
